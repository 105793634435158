import { Suspense, lazy } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import Navbar from "./containers/NavBar";
import Footer from "./containers/FooterBar";
import Loading from "./Loading";

const Landing = lazy(() => import("./routes/Landing"));
const PageNotFound = lazy(() => import("./routes/PageNotFound"));

const App = (props) => {

    return (
        <Router>
            <Navbar />
            <Suspense fallback={<Loading />}>
                <Switch>
                    <Route exact path="/" {...props} component={Landing} />
                    <Route component={PageNotFound} />
                </Switch>
            </Suspense>
            <Footer />
        </Router>
    );
};

export default App;
