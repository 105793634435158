import { withRouter } from "react-router";
import { Nav, Navbar, Button, Container, Row, Col } from 'react-bootstrap'
import './index.scss'

const NavbarMain = () => {

	const navBar = props => {
		return (
			<Navbar collapseOnSelect expand="lg" bg="light" variant="light" sticky="top">
				<Container>
					<Navbar.Brand href='/'>
						<Row>
							<Col className='logo'>
								<img
									src='/logo.png'
									alt='ardeesoft'
								/>
							</Col>
							<Col className='mt-2 logo-name'>
								ardeesoft
							</Col>
						</Row>
					</Navbar.Brand>
					<Navbar.Toggle aria-controls="responsive-navbar-nav" />
					<Navbar.Collapse id="responsive-navbar-nav">
						<Nav className="me-auto"></Nav>
						<Nav>
							<Nav.Link className='mt-1' href="/#">Home</Nav.Link>
							<Nav.Link className='mt-1' eventKey={2} href="#about">About</Nav.Link>
							<Nav.Link className='mt-1' eventKey={3} href="#platforms">Products</Nav.Link>
							<Nav.Link eventKey={4} href="#contact"><Button size='sm'>Contact</Button></Nav.Link>
						</Nav>
					</Navbar.Collapse>
				</Container>
			</Navbar>

		);
	};

	const HeaderWithRouter = withRouter(navBar);

	return (
		<HeaderWithRouter />
	);


};

export default NavbarMain;
