import { withRouter } from "react-router";
import { Container, Row, Col } from 'react-bootstrap'
import { FaChevronRight, FaTwitterSquare, FaFacebookSquare, FaLinkedin } from 'react-icons/fa'
import './index.scss'

const FooterMain = () => {

	const footerBar = props => {
		return (
			<>
				<div className='footer-top'>
					<Container>
						<Row>
							<Col lg={4} md={6}>
								<Row>
									<h3 style={{ color: '#FFFFFF' }}><strong>ardeesoft</strong></h3>
								</Row>
								<Row>
									<p style={{ fontSize: '12px', color: '#FFFFFF' }}>
										Lawrenceville, GA 30043<br />
										United States
									</p>
								</Row>
								<Row>
									<p style={{ fontSize: '12px', color: '#FFFFFF' }}><strong>Phone:</strong> +1 770 580 0818<br />
										<strong>Email:</strong> info@ardeesoft.com</p>
								</Row>
							</Col>
							<Col lg={4} md={6}>
								<h4 style={{ color: '#FFFFFF' }}>Our Services</h4>
								<FaChevronRight style={{ fontSize: '11px', color: '#FFFFFF' }} /> <a href='#contact'>NetSuite Profesional Services</a><br />
								<FaChevronRight style={{ fontSize: '11px', color: '#FFFFFF' }} /> <a href='#contact'>Integration Solutions</a><br />
								<FaChevronRight style={{ fontSize: '11px', color: '#FFFFFF' }} /> <a href='#contact'>Custom Software Solutions</a>
							</Col>
							<Col lg={4} md={6}>
								<h4 style={{ color: '#FFFFFF' }}>Useful Links</h4>
								<FaChevronRight style={{ fontSize: '11px', color: '#FFFFFF' }} /> <a href='#contact'>Home</a><br />
								<FaChevronRight style={{ fontSize: '11px', color: '#FFFFFF' }} /> <a href='#contact'>About</a><br />
								<FaChevronRight style={{ fontSize: '11px', color: '#FFFFFF' }} /> <a href='#contact'>Services</a>
							</Col>
						</Row>
					</Container>
				</div>
				<div className='footer-bottom'>
					<Container className='p-3'>
						<Row>
							<Col className='text-start'>
								&copy; Copyright <strong><span>Ardeesoft LLC</span></strong>
							</Col>
							<Col className='text-end'>
								<a href='https://twitter.com/ardeesoft'><FaTwitterSquare style={{ fontSize: '24px', marginRight: '10px' }} /></a>
								<a href='https://www.facebook.com/ardeesoft'><FaFacebookSquare style={{ fontSize: '24px', marginRight: '10px' }} /></a>
								<a href='https://www.linkedin.com/company/ardeesoft'><FaLinkedin style={{ fontSize: '24px', marginRight: '10px' }} /></a>
							</Col>
						</Row>
					</Container>
				</div>
			</>


		);
	};

	const FooterWithRouter = withRouter(footerBar);

	return (
		<FooterWithRouter />
	);


};

export default FooterMain;
